//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MediaPick from '@/components/Gallery/MediaPick.vue'
export default {
    name: 'CoverPhoto',
    components: { MediaPick },
    props: [
      'entity_type_id',
      'entity_id',
      'isOwner',
    ],
    created() {
      this.loadImage();
    },
    data() {
      return {
        src: null,
        file: null,
        isLoading: false,
        coverPhoto: null,
        showDialog: false,
        selected_id: null,
      }
    },
    computed:{
      entity_type (){
        if(this.entity_type_id == '1'){
          return  'group'
        }else if(this.entity_type_id == '2'){
          return  'event'
        }else{
          return 'store'
        }
      }
    },
    methods: {
      onImgError() {
            this.$q.notify({
              message:"Could not load image. Falling back to default cover photo.",
              color: 'negative',
              icon: 'error'
            })
            this.src = ''
            this.isLoading = false
      },
      selectPhoto() {
        this.$refs.filePicker.pickFiles();
      },
      chooseGallery(){
        this.showDialog = true;
      },
      updateMedia(id){
        this.selected_id = id;
        this.payload = {
          id: this.entity_id,
          media_id: id
        }
        this.$q.dialog({
          message:'Are you sure you want to use this photo?',
          cancel: true,
        })
        .onOk(() => {
          this.$store.dispatch(this.entity_type + '/UpdateCoverPhotoByID',this.payload)
            .then((r) => {
              if(r.status == 'success'){
                this.$q.notify({
                  message: 'Successfully update cover photo',
                  color:'positive',
                  icon: 'check_circle'
                })
              }else{
                this.$q.notify({
                  message: 'Sorry, We cant process your request right now',
                  color:'negative',
                  icon: 'error'
                })
              }
              location.reload()
            })
        })
        .onCancel(() =>{})
        .onDismiss(() => {})
      },
      loadImage() {
        this.isLoading = true
        let payload = {
          id: this.$route.params.id
        }
          this.$store.dispatch(this.entity_type+'/getCoverPhoto', payload)
          .then((response) =>{

            if(response.status == 'success'){
              this.coverPhoto = response.image
              this.isLoading = false;
            }else{
              this.isLoading = false;
            }
          })
          
      },
      uploadImage() {
            if (!this.file) {
                return;
            }
            
            this.$q.dialog({
              title:'Change Cover Photo',
              message: 'Are you sure you want to use this as a cover photo?',
              cancel: true
            })
            .onOk(() => {
              var formData = new FormData();
              formData.append('file', this.file);
              formData.append('entity_id', this.entity_id);
              formData.append('entity_type_id', this.entity_type_id);
              var payload = {
                  id: this.entity_id,
                  data: formData
              }
              this.isLoading = true;
                this.$store.dispatch(this.entity_type+'/uploadPhoto',payload)
                .then((status) =>{
                  if(status == 'success'){
                    this.loadImage()
                    this.$q.notify({
                      message:"Cover Photo successfuly updated",
                      color:'positive',
                      icon: 'check_circle'
                    })
                    location.reload()
                  }else{
                    this.src = this.$store.state.app.defaultCoverPhoto
                  }
                })
            
              this.isLoading = false;
            })
            .onCancel(() =>{})
            .onDismiss(()=>{})
            
        }      
    }
}
